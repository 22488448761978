<template>
  <div class="everiting_box_body">
    <b-img-lazy
      v-bind="mainProps"
      :src="(slide.categoryId === 'Flight_Only' || slide.categoryId === 'Organize_tour_packages')? getDestinationImage(slide.destinationCode): slide.perspectiveUrl[0] || slide.imageUrl || `${imageDomain}/assets/img/lesiurpackages_hotelsimg3.jpg`"
      alt="Not Found"
      :onerror="`this.src='${imageDomain}/assets/img/lesiurpackages_hotelsimg4.jpg'`"
      width="100" height="100"
    ></b-img-lazy>
    <div class="contain_body">
      <div class="card-title">

        <h6 v-if="slide.categoryId === 'Flight_Only'">
          <div class="title-letter pt-4">
            <!-- {{ $t("search-result.flight") }}: תל אביב - {{ slide.destinationName['he']}} ,{{ slide.destinationCountryName[lang] }} - תל אביב -->
            תל אביב - {{ slide.destinationName['he']}} - תל אביב
          </div>
          <span></span>
        </h6>

        <h6 v-else>
          <span>{{ slide.destinationName['he']}}, {{ slide.destinationCountryName[lang] }}</span>
          <div class="title-letter">{{ slide.hotelName['en'] }}</div>
        </h6>

        <b-form-rating  v-if="slide.packageType !== 'F' && slide.categoryId !== 'Organize_tour_packages' && slide.stars" class="ui rating" v-model="slide.stars" readonly></b-form-rating>
      </div>
      <div class="btn btn-primary category-text-button" v-if="slide.categoryId === 'Organize_tour_packages'">{{ $t("search-result.organize-tour") }}</div>
      <div class="btn btn-primary category-text-button" v-if="slide.categoryId === 'Flight_Only'">{{ $t("search-result.flight-only") }}</div>
      <div class="btn btn-primary category-text-button" v-if="slide.categoryId === 'vacation_pack'">{{ $t("search-result.vacation-package") }}</div>
      <p class="first-paragraph">
        <span class="icon"><i class="fas fa-plane-departure"></i></span>
        {{ $t("home.slide-deal.round-trip") }} :
        {{ changeDateFomart(slide.departureDate) }}
      </p>
      <p>
        <span class="icon"><i class="fas fa-plane-arrival"></i></span>
        {{ $t("home.slide-deal.flight-return") }} :
        {{ changeDateFomart(slide.arrivalDate) }}
      </p>
      <p v-if="slide.packageType !== 'F'" >
        <span class="icon"><i class="fas fa-bus-alt"></i></span>
        {{ $t("home.slide-deal.transfers") }} : {{ slide.transfers ? slide.transfers : "" }}
      </p>
      <p v-if="slide.packageType !== 'F'">
        <span class="icon"><i class="fas fa-utensils"></i></span>
        {{ $t("home.slide-deal.hosting-base") }} : {{ slide.boardBasisName[lang] }}
      </p>
      <p v-if="slide.packageType === 'F'">
        <span class="icon"><i class="fas fa-suitcase-rolling"></i></span>
        {{ $t("flight-only.luggage-plane-desc") }}, {{ $t("flight-only.max") }}:
        {{ slide.roundTripFlight.FlightDetail[0].FL_Max_Weight }}Kg
      </p>
      <p v-if="slide.packageType === 'F'">
        <span class="icon"><i class="fas fa-briefcase"></i></span>
        {{ $t("flight-only.including-small-desc") }} , {{ $t("flight-only.max") }}:
        {{ slide.roundTripFlight.FlightDetail[0].FL_Max_Weight_Trolley }}Kg
      </p>
      <div class="price pt-2">
        {{slide.packageType === "F" ? slide.discountedPriceFO.priceByAge[0].price : slide.discountedPrice.price_average}}{{slide.currenySymbol}}
        <span class="price-remark">{{ this.priceRemark }}</span>
      </div>
      <!-- <a id="reservation" :href="slide.selectedPackageUrl" v-if="isLanding"> -->
      <a id="reservation" :href="`${slide.selectedPackageUrl}&roomClass=${slide.roomClass}${utmSourceQuery}`" v-if="isLanding">
        <div class="order-button btn-primary" :class="`${this.landingInfo.utm_source} - ${this.slide.destinationName.he}`">
          {{ $t("home.slide-deal.order-button") }}
        </div>
      </a>
      <!-- <a :href="slide.selectedPackageUrl" v-else> -->
      <a :href="slide.toOldSite ? `${slide.productPageUrlInOldSite}${utmSourceQuery}${fcAgentModeParam}` : `${slide.selectedPackageUrl}&roomClass=${slide.roomClass}${utmSourceQuery}${fcAgentModeParam}`" v-else>
        <div class="order-button btn-primary">
          {{ $t("home.slide-deal.order-button") }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BImgLazy, BFormRating } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'LesiurPackageItem',
  mixins: [imageUrlMixin],
  props: ['slide'],
  components: {
    BFormRating,
    BImgLazy,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      destinationImages: 'GET_DESTINATION_IMAGES',
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    fcAgentModeParam() {
      return (this.isFcAgentMarketerMode) ? '&fc-agent-mode' : '';
    },
  },
  watch: {
    lang() {
      this.priceRemark = this.slide.priceRemarkTrans[this.lang] ? this.slide.priceRemarkTrans[this.lang] : this.slide.priceRemarkTrans.he;
    },
  },
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      host: window.location.host,
      priceRemark: '',
      utmSourceQuery: this.$route.query.utm_source ? `&utm_source=${this.$route.query.utm_source}` : '',
    };
  },
  created() {
    this.priceRemark = this.slide?.priceRemarkTrans?.he ? this.slide.priceRemarkTrans.he : '';
  },
  mounted() {
    // this.$log.debug(window.location);
  },
  methods: {
    changeDateFomart(str) {
      return str.split('-').reverse().join('/');
    },
    getDestinationImage(dest) {
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      let url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg3.jpg`;
      if (!url) url = `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg3.jpg`;
      return url;
    },
  },
};
</script>
<style scoped>
.btn.category-text-button{
  margin: 120px auto 0 auto;
  background: #005ca3;
  border: 1px solid rgb(255 255 255 / 0.5);
  border-radius: 30px;
  width: fit-content;
  text-transform: capitalize;
  padding: 5px 30px;
}
.everiting_box_body{
  direction: rtl;
}
.everiting_box_body .contain_body .card-title .title-letter{
  padding-left: 10%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  direction: rtl;
}
.everiting_box_body:hover .contain_body .card-title .title-letter{
  padding-left: 0;
  overflow: inherit;
  max-height: inherit;
}

.everiting_box_body .contain_body .card-title {
  position: absolute;
  z-index: 1;
  margin: 0;
  padding: 5px 0;
  top: 20px;
  right: 5px;
  width: 95%;
  color: white;
  transition: 0.5s;
}
.everiting_box_body:hover .card-title {
  width: 105%;
}
.everiting_box_body .card-title:after {
  content: "";
  height: 100%;
  width: 110%;
  position: absolute;
  display: block;
  top: 0;
  right: -20%;
  transform: skew(25deg, 0deg);
  z-index: -1;
  background-color: #00adef;
  box-shadow: #023b50 2px 10px 15px;
  height: 75px;
}
.everiting_box_body .ui.rating {
  height: auto;
  color: #025575;
  background: transparent;
  border: none;
  direction: rtl;
  padding: 0;
}
.everiting_box .everiting_box_body .price {
  font-size: 30px;
}
</style>
<style>
.everiting_box_body .ui.rating span.b-rating-star {
  display: contents;
}
.everiting_box .everiting_box_body .price {
  display: block;
  direction: rtl;
}
.everiting_box .everiting_box_body .price .price-remark {
  font-size: initial;
  font-weight: initial;
}
.everiting_box .everiting_box_body .order-button:hover {
  transform: scale(1.05);
}
.everiting_box .everiting_box_body .order-button {
  transition-duration: 0.1s;
  content: "";
  background: #005ca3;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  margin: 0 auto;
  padding: 5px;
  width: 100px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 30px;
  z-index: 91;
  border: 1px solid rgb(255 255 255 / 0.5);
}
.deal-container .everiting_box .everiting_box_body .contain_body p{
  font-size: 1.2rem;
}
.deal-container .everiting_box .everiting_box_body .price {
  font-size:  1.5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .everiting_box_body .ui.rating span.b-rating-star {
    display: inline-table;
  }
}
</style>
